import {
  Center,
  Flex,
  HStack,
  Heading,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import WppButton from "./wppbutton";

export default function SectionF() {
  return (
    <Center py={{ base: "20px", lg: "40px" }} bg="#042032">
      <HStack>
        <Image
          src="/assets/logo-a.png"
          objectFit={"contain"}
          width={{ base: "40%", lg: "400px" }}
          px="12px"
          maxW="400px"
        />
        <VStack alignItems={"flex-start"} spacing={{ base: "4px", lg: "20px" }}>
          <Heading fontSize={{ base: "10px", lg: "34px" }}>
            Atendimento on-line em todo Brasil
          </Heading>
          <WppButton showCirculeButton title="Falar no WhatsApp " />
          <Flex direction={"column"}>
            <Text fontSize={{ base: "10px", lg: "30px" }}>98 99214 2156</Text>
            <Text fontSize={{ base: "10px", lg: "30px" }}>
              rpiorskyjr@gmail.com
            </Text>
          </Flex>
        </VStack>
      </HStack>
    </Center>
  );
}
