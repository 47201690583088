import { Flex } from "@chakra-ui/react";

export default function SectionC() {
  return (
    <Flex
      h={{ base: "400px", lg: "600px", "2xl": "800px" }}
      backgroundImage={"/assets/9.png"}
      backgroundSize={{ base: "400px", lg: "600px", "2xl": "800px" }}
      backgroundPosition={{
        base: "bottom",
        lg: "bottom",
      }}
      backgroundRepeat={"no-repeat"}
      mt={{ base: "-80px", lg: "-160px" }}
      pt={{ base: "110px", lg: "220px" }}
      pl="20px"
      fontWeight={300}
      alignItems={{ base: "flex-start", lg: "center" }}
      paddingRight={{ base: 0, lg: "400px" }}
      direction={"column"}
    ></Flex>
  );
}
