import { Center, Flex, Heading, Text, VStack } from "@chakra-ui/react";
import WppButton from "./wppbutton";
import { styles } from "../styles";
import { motion } from "framer-motion";

export default function SectionD() {
  return (
    <Center
      py={{ base: "20px", lg: "40px" }}
      bg="#042032"
      px={{ base: "20px", lg: "100px" }}
      flexDir={"column"}
    >
      <VStack
        w="100%"
        px={{ base: "14px" }}
        // lineHeight={"14px"}
        // maxW={{ base: "400px", lg: "500px", "2xl": "600px" }}
        spacing={{ base: "auto", lg: "12px" }}
        // h={{ base: "370px", lg: "500px", "2xl": "800px" }}
        // maxH={{ base: "370px", lg: "500px", "2xl": "800px" }}
      >
        <Heading
          fontSize={{ base: "2xl", lg: "4xl", "2xl": "5xl" }}
          fontWeight={400}
          alignSelf={"center"}
          textAlign={"left"}
          mb="20px"
          // w="70%"
          // maxW="300px"
        >
          Como <i style={{ color: "#F7A933" }}>funciona</i> o<br /> nosso
          <b> atendimento</b>
          <br /> 100% on-line:
        </Heading>
        <Center
          sx={styles.listItem}
          as={motion.div}
          initial={{ x: 100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
        >
          <Center sx={styles.listItemIcon}>1</Center>
          <Text sx={styles.text}>
            Clique no botão abaixo para falar pelo WhatsApp e iniciar uma
            conversa comigo. <b>Você vai poder falar sobre o seu caso!</b>
          </Text>
        </Center>
        <Center
          sx={styles.listItem}
          as={motion.div}
          initial={{ x: 100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
        >
          <Center sx={styles.listItemIcon}>2</Center>
          <Text sx={styles.text}>
            Iniciada a consulta, vou te fazer algumas perguntas para entender
            melhor o seu caso, solicitar alguns documentos e definir{" "}
            <b style={{ color: "#F7A933" }}>a melhor estratégia</b> para buscar
            os seus direitos.
          </Text>
        </Center>
        <Center
          sx={styles.listItem}
          as={motion.div}
          initial={{ x: 100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
        >
          <Center sx={styles.listItemIcon}>3</Center>
          <Text sx={styles.text}>
            Posso te atender de qualquer lugar do Brasil, de forma on-line, com
            a maior praticidade e rapidez.
          </Text>
        </Center>
      </VStack>
      <Flex>
        <WppButton title="Falar no WhatsApp" showCirculeButton />
      </Flex>
    </Center>
  );
}
