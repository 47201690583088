export const styles = {
  listItem: {
    backgroundColor: "#3e5462",
    borderRadius: "8px",
    marginBottom: "20px",
    pl: { base: "12px", lg: "22px" },
    pr: { base: "22px", lg: "32px" },
    py: "12px",
    // pr: { base: "8px", lg: "4px" },
    maxW: "900px",
    fontSize: { base: "12px", lg: "22px" },
    textAlign: "justify",
  },
  listItemIcon: {
    height: "100%",
    minWidth: "64px",
    marginLeft: { base: "-44px", lg: "-54px" },
    color: "#F7A933",
    fontWeight: "bold",
    fontSize: { base: "44px", lg: "6xl" },
  },
  listItemIconImage: {
    height: "100%",
    width: { base: "200px", lg: "250px" },
    mr: "22px",
    marginLeft: { base: "-34px", lg: "-70px" },
    color: "#F7A933",
    fontWeight: "bold",
  },
  text: {
    fontSize: { base: "14px", lg: "2xl" },
  },
};
