export default function SectionG() {
  return (
    <iframe
      title="map-location"
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7971.997941254426!2d-44.2657545!3d-2.5072084!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7f691c3f73380b3%3A0xeb34cffca25c0950!2sPiorsky%20Junior%20-%20Advocacia%20Especializada!5e0!3m2!1spt-BR!2sbr!4v1697812677420!5m2!1spt-BR!2sbr"
      width={window.innerWidth}
      height="450px"
      style={{ border: 0 }}
      allowFullScreen
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  );
}
