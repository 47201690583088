import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react";

export default function SectionE() {
  return (
    <Flex
      minH={{ base: "420px", lg: "700px" }}
      backgroundImage={"/assets/10.png"}
      backgroundSize={{ base: "380px", lg: "700px" }}
      backgroundPosition={{
        base: "bottom 0 left -100px",
        lg: "left 12vw bottom 0",
      }}
      backgroundRepeat={"no-repeat"}
      // pl="20px"
      pt={{ base: "20px" }}
      fontWeight={300}
      alignItems={{ base: "flex-end", lg: "center" }}
      justifyContent={{ base: "center" }}
      paddingLeft={{ base: 0, lg: "600px" }}
      fontSize={{ base: "12px", lg: "20px" }}
      direction={"column"}
    >
      <Accordion
        maxW={{ base: "60%", lg: "600px" }}
        mr={{ base: "12px" }}
        allowToggle
        my={{ base: "12px", lg: "20px" }}
      >
        <Heading
          textAlign={"center"}
          fontSize={{ base: "md", lg: "2xl" }}
          fontWeight={400}
          w="full"
          mb="12px"
          fontFamily={"Poppins"}
        >
          DÚVIDAS FREQUENTES
        </Heading>
        <AccordionItem borderTopWidth={0}>
          <h2>
            <AccordionButton
              sx={styles.accordionBtn}
              justifyContent={"flex-end"}
            >
              Minha causa é ganha?
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel sx={styles.accordionPanel}>
            Ainda não posso afirmar isso - mas saiba que sempre faço o meu
            melhor pelos meus clientes, e com você não será diferente!
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton sx={styles.accordionBtn}>
              Sou de outra cidade ou estado. Como será meu atendimento?
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel sx={styles.accordionPanel}>
            A grande vantagem do atendimento totalmente online é que, não
            importa de onde você seja - isso não interfere em nada. Se desejar
            entrar em contato comigo, é só falar pelo WhatsApp. E, durante a sua
            audiência, eu vou te acompanhar.
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton sx={styles.accordionBtn}>
              Como acompanharei o meu processo?
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel sx={styles.accordionPanel}>
            Você sabia que todo novo processo é gerenciado 100% digitalmente?
            Assim, todo o procedimento pode ser conduzido online. Isso se
            estende também ao meu atendimento e interações com você, que podem
            ser realizados exclusivamente pela internet. E a evolução do seu
            caso poderá ser acompanhada diretamente comigo.
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton sx={styles.accordionBtn}>
              Quanto tempo demora um processo trabalhista?
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel sx={styles.accordionPanel}>
            <Text>
              Essa é uma pergunta complexa, pois a duração varia
              significativamente em diferentes regiões do Brasil.
            </Text>
            <Text>
              Contudo, podemos estimar (com base em uma média) a duração de um
              processo trabalhista em dois cenários distintos:
            </Text>
            <Text>
              - Se houver um acordo entre as partes na primeira audiência: o
              processo tende a durar aproximadamente 5 meses.
            </Text>
            <Text>
              - Se o juiz decidir o caso e nenhuma das partes recorrer: em
              média, 1 ano.
            </Text>
            <Text>
              No entanto, se qualquer uma das partes decidir recorrer da decisão
              do juiz, fica difícil prever quanto tempo o processo irá demorar
              para ser concluído.
            </Text>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton sx={styles.accordionBtn}>
              Posso entrar com um processo enquanto estou trabalhando?
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel sx={styles.accordionPanel}>
            Sim, não existe restrição legal para dar entrada em um processo
            enquanto ainda está empregado na empresa.
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem borderBottomColor={"transparent"}>
          <h2>
            <AccordionButton sx={styles.accordionBtn}>
              Quem perde ação trabalhista tem que pagar?
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel sx={styles.accordionPanel}>
            Se o empregado for reconhecido como carente, usufruindo do benefício
            da justiça gratuita, ele não será responsável pelo pagamento das
            custas judiciais nem pelos honorários periciais.
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Flex>
  );
}

const styles = {
  accordionBtn: {
    fontFamily: "Poppins",
    textAlign: { base: "right", lg: "left" },
    justifyContent: { base: "flex-end", lg: "space-between" },
    px: {
      base: "12px",
    },
    fontSize: {
      base: "12px",
      lg: "20px",
    },
    py: {
      base: "2px",
      lg: "6px",
    },
  },
  accordionPanel: {
    py: {
      base: "4px",
      lg: "12px",
    },
    // maxH: {
    //   base: "100px",
    //   lg: "200px",
    // },
    fontSize: { base: "12px", lg: "22px" },
    overflowY: "auto",
  },
};
