import { PhoneIcon } from "@chakra-ui/icons";
import { Button, HStack, Icon, Link, Text } from "@chakra-ui/react";

interface IWppButton {
  title: string;
  showCirculeButton?: boolean;
}

export default function WppButton({ title, showCirculeButton }: IWppButton) {
  return (
    <HStack>
      <Button
        colorScheme="whatsapp"
        size={{ base: "xs", lg: "xl" }}
        w={
          showCirculeButton
            ? { base: "140px", lg: "300px" }
            : { base: "180px", lg: "340px" }
        }
        fontWeight={300}
        py="16px"
        bg="#64B853"
        as={Link}
        isExternal
        href="https://api.whatsapp.com/send?phone=5598992142156&text=Ol%C3%A1%2C%20vim%20atrav%C3%A9s%20do%20site%2C%20gostaria%20de%20falar%20com%20um%20advogado%20especialista%20em%20direito%20trabalhista"
      >
        <Text
          fontSize={
            showCirculeButton
              ? { base: "11px", lg: "24px" }
              : { base: "xs", lg: "24px" }
          }
        >
          {title}
        </Text>
      </Button>
      {showCirculeButton && (
        <Button
          id="circle-wpp-button"
          as={Link}
          borderWidth={"3px"}
          borderColor={"white"}
          size="sm"
          colorScheme="whatsapp"
          h={{ base: "30px", lg: "50px" }}
          w={{ base: "30px", lg: "50px" }}
          _hover={{
            transform: "scale(1.05)",
          }}
          zIndex={9999}
          href="https://api.whatsapp.com/send?phone=5598992142156&text=Ol%C3%A1%2C%20vim%20atrav%C3%A9s%20do%20site%2C%20gostaria%20de%20falar%20com%20um%20advogado%20especialista%20em%20direito%20trabalhista"
          isExternal
          borderRadius={"full"}
        >
          <Icon
            as={PhoneIcon}
            h={{ base: "16px", lg: "22px" }}
            w={{ base: "16px", lg: "22px" }}
          />
        </Button>
      )}
    </HStack>
  );
}
