import { useEffect } from "react";
import "./App.css";
import Footer from "./components/footer";
import SectionA from "./components/sectiona";
import SectionB from "./components/sectionb";
import SectionC from "./components/sectionc";
import SectionD from "./components/sectiond";
import SectionE from "./components/sectione";
import SectionF from "./components/sectionf";
import SectionG from "./components/sectiong";

function App() {
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get("section") !== null) {
      const rightsContainer = document.getElementById("direitos");
      if (rightsContainer) {
        rightsContainer.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, []);
  return (
    <div className="App">
      <SectionA />
      <SectionB />
      <SectionC />
      <SectionD />
      <SectionE />
      <SectionF />
      <SectionG />
      <Footer />
    </div>
  );
}

export default App;
