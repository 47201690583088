import { Button, Center, Link } from "@chakra-ui/react";

export default function Footer() {
  return (
    <Center bg="#121212" py={{ base: "20px", lg: "40px" }}>
      <Button
        variant={"link"}
        fontSize={{ base: "14px", lg: "20px" }}
        color="gray.50"
        as={Link}
        isExternal
        href="https://www.instagram.com/brandpro.ag/"
      >
        Site desenvolvido por Agência Open
      </Button>
    </Center>
  );
}
