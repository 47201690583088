import { Flex, Image, Text, VStack } from "@chakra-ui/react";
import WppButton from "./wppbutton";

export default function SectionA() {
  return (
    <Flex
      w="full"
      h={{ base: "380px", lg: "90vh" }}
      backgroundImage="url(/assets/8.png)"
      backgroundSize={{ base: "350px", lg: "90vh" }}
      backgroundRepeat={"no-repeat"}
      backgroundPosition={{
        base: "bottom 0% left 110px",
        sm: "bottom 0 left 40vw",
        lg: "bottom 0% right 15vw",
        "2xl": "bottom 0% right 13vw",
      }}
      bgColor="#121212"
      justifyContent={{ base: "flex-start", lg: "center" }}
      alignItems={{ base: "center" }}
    >
      <VStack
        w={{ base: "55%", lg: "full" }}
        maxW="840px"
        alignItems={{ base: "center", lg: "flex-start" }}
        // pt={{ base: "3vh" }}
        pl={{ base: "20px", "2xl": "0" }}
        ml={{ base: "0px", "2xl": "-180px" }}
        textAlign={"left"}
        mb="-20px"
      >
        <Image
          src="/assets/logo-b.png"
          //   h={{ base: "150px" }}
          w={{ base: "200px", lg: "300px" }}
          objectFit="contain"
          mb={{ base: "-30px", lg: "-60px" }}
        />
        <Text
          lineHeight={{ base: "22px", lg: "44px", "2xl": "60px" }}
          fontSize={{ base: "20px", lg: "42px", "2xl": "50px" }}
          pl="12px"
          mb={{ base: "10px", "2xl": "20px" }}
          maxW="420px"
        >
          Trabalhou <br />
          sem carteira assinada?
        </Text>
        <Text
          lineHeight={{ base: "14px", lg: "28px", "2xl": "30px" }}
          fontSize={{ base: "12px", lg: "20px", "2xl": "26px" }}
          pl="12px"
          mb={{ base: "10px", lg: "24px" }}
          maxW={{ base: "250px", lg: "420px" }}
        >
          Fale agora com um advogado trabalhista on-line e saiba seus direitos.
        </Text>

        <Flex>
          <WppButton title="Quero falar com advogado" />
        </Flex>
      </VStack>
    </Flex>
  );
}
