import { Center, Heading, Image, Text, VStack } from "@chakra-ui/react";
import { styles } from "../styles";
import { motion } from "framer-motion";

export default function SectionB() {
  return (
    <Center
      id="direitos"
      bg="#042032"
      p={{ base: "40px 28px", lg: "40px 100px 80px 100px" }}
      fontWeight={300}
      flexDir={"column"}
    >
      <Heading
        fontSize={{ base: "lg", lg: "4xl" }}
        mb="18px"
        textAlign={"center"}
        fontWeight={"thin"}
      >
        Você tem direitos trabalhistas
        <br />
        <span style={{ color: "#F7A933" }}>garantidos por lei</span> nesses
        casos:
      </Heading>
      <VStack
        spacing={"0px"}
        my="20px"
        fontSize={{ base: "sm", lg: "3xl" }}
        px={{ base: "12px", lg: undefined }}
      >
        <Center
          sx={styles.listItem}
          as={motion.div}
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
        >
          <Center sx={styles.listItemIconImage}>
            <Image src="/assets/icons/1.png" />
          </Center>
          <Text>
            Você trabalhou sem carteira assinada ou foi demitido(a) e não
            recebeu todos os seus direitos? Ou você foi demitida grávida ou com
            estabilidade? <b>Eu posso te ajudar!</b>
          </Text>
        </Center>
        <Center
          sx={styles.listItem}
          as={motion.div}
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
        >
          <Center sx={styles.listItemIconImage}>
            <Image src="/assets/icons/2.png" />
          </Center>
          <Text>
            <b>
              Se a empresa não concede ou não paga as suas férias ou se você não
              recebeu as horas trabalhadas além da sua jornada
            </b>
            , trabalharei para defender seus interesses!
          </Text>
        </Center>
        <Center
          sx={styles.listItem}
          as={motion.div}
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
        >
          <Center sx={styles.listItemIconImage}>
            <Image src="/assets/icons/3.png" />
          </Center>
          <Text>
            Se você sofreu um acidente de trabalho, trabalha em{" "}
            <b>ambiente insalubre ou exerce atividade perigosa</b>, saiba que
            lei protege os seus direitos e eu posso ajudar você!
          </Text>
        </Center>
        <Center
          sx={styles.listItem}
          as={motion.div}
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
        >
          <Center sx={styles.listItemIconImage}>
            <Image src="/assets/icons/4.png" />
          </Center>
          <Text>
            Se você está com o <b>FGTS atrasado</b>, foi demitido por{" "}
            <b>não bater metas</b> ou
            <b>não recebeu suas comissões</b>, saiba que como Advogado
            Trabalhista, eu também posso te ajudar!
          </Text>
        </Center>
        <Center
          sx={styles.listItem}
          as={motion.div}
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
        >
          <Center sx={styles.listItemIconImage}>
            <Image src="/assets/icons/5.png" />
          </Center>
          <Text>
            Infelizmente,{" "}
            <b>
              caso tenha acontecido de você sofrer algum tipo de assédio, seja
              ele moral ou sexual no seu trabalho
            </b>
            , a lei te protege e garante seus direitos.
          </Text>
        </Center>
      </VStack>
    </Center>
  );
}
